
///var/www/website2024/192.testproject.work/website2024/s-302-dating/website/my-app/src/page/Profile.js

import React, { useEffect, useState, useRef, useCallback } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import { ProgressSpinner } from 'primereact/progressspinner';
import ProfileDetails from '../component/ProfileDetails';
import Sidebar from '../component/Sidebar';
import Matching from '../component/Matching';
import Subscription from '../component/Subscription';
import Billing from '../component/Billing';
import '../style/Profile.scss';
import ToastNotification from '../component/ToastNotification';
import { fetchProfile, updateProfile } from '../api/api'; // Import API functions

const Profile = () => {
  const [profile, setProfile] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [activeComponent, setActiveComponent] = useState('profile');
  const navigate = useNavigate();
  const toastRef = useRef(null);

  const loadProfile = useCallback(async () => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      const user = JSON.parse(storedUser);
      try {
        console.log('Fetching profile for user:', user.user);  // Log user for debugging
        const profileData = await fetchProfile(user.user, user.token);
        setProfile(profileData);
        localStorage.setItem('profile', JSON.stringify(profileData));
      } catch (error) {
        console.error('Error fetching profile:', error);  // Log full error details
      }
    } else {
      console.warn('No user found in local storage, redirecting to login.');
      navigate('/login');
    }
  }, [navigate]);
  
  useEffect(() => {
    loadProfile();
  }, [loadProfile]);

  const handleEditProfile = async (updatedProfile) => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      const user = JSON.parse(storedUser);

      const updatedPayload = {
        ...updatedProfile,
        birthday: new Date(updatedProfile.birthday).toISOString().split('T')[0],
      };

       // Exclude the specified fields
       const { amphoeID, amphureNameTH, jangwatID, jangwatNameTH, position_ID, status_ID, thamboonID, thamboonNameTH, date, time, ...filteredPayload } = updatedPayload;
  

      try {
        await updateProfile(user.user, user.token, filteredPayload);
        await loadProfile(); // Refresh the profile data
        setEditMode(true); // Remain in edit mode
        toastRef.current.showSuccess('Profile updated successfully!');
      } catch (error) {
        console.error('Error updating profile:', error);
        toastRef.current.showError('Failed to update profile.');
      }
    }
  };

  if (!profile) {
    return <ProgressSpinner />;
  }

  const renderActiveComponent = () => {
    switch (activeComponent) {
      case 'profile':
        return (
          <ProfileDetails
            profile={profile}
            editMode={editMode}
            setEditMode={setEditMode}
            onSave={handleEditProfile}
          />
        );
      case 'matching':
        return <Matching />;
      case 'subscription':
        return <Subscription />;
      case 'billing':
        return <Billing />;
      default:
        return null;
    }
  };

  return (
    <div className="profile-page">
      <Sidebar setActiveComponent={setActiveComponent} />
      <div className="main-content">
        {renderActiveComponent()}
      </div>
      <ToastNotification ref={toastRef} />
    </div>
  );
};

export default Profile;
