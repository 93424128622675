///var/www/website2024/192.testproject.work/website2024/s-302-dating/website/my-app/src/component/Subscription.js
import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';

const Subscription = () => {
  const [packages, setPackages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);  // Add error state

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      const user = JSON.parse(storedUser);
      if (user?.user && user?.token) {  // Ensure both user ID and token exist
        fetchUserPackages(user.user, user.token);
      } else {
        setError('Invalid user data');  // Set error for invalid user
        setLoading(false);
      }
    } else {
      setError('No user found');  // Set error if no user is stored
      setLoading(false);
    }
  }, []);

  const fetchUserPackages = async (userId, token) => {
    try {
      const response = await axios.get(`https://py-maracar.afaa.website/matching/api_env_2/user?user_id=${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
  
      // Extract the user_packages array from the response
      const userPackages = response.data[0]?.user_packages || [];
      console.log('User Packages:', userPackages);  // Log to verify the correct data
  
      setPackages(userPackages);  // Set only the user_packages data to state
    } catch (error) {
      console.error('Error fetching user packages:', error);
      setError('Failed to fetch packages');
    } finally {
      setLoading(false);
    }
  };
  

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;  // Display error message if any
  }

  return (
    <div className="container profile-container mt-5">
      <div className="profile-card">
        <h2>Packages</h2>
        {packages.length > 0 ? (
          packages.map((pkg, index) => (
            <div key={index} className="package-detail">
              <h5>{pkg.package}</h5>
              <p>Price: {pkg.price}</p>
              <p>Status: {pkg.status_name}</p>
              <p>Date: {new Date(pkg.date).toLocaleDateString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' })}</p>

              <p>Time: {pkg.time}</p>
            </div>
          ))
        ) : (
          <p>No subscription packages available.</p>
        )}
      </div>
    </div>
  );
};

export default Subscription;
