///var/www/website2024/192.testproject.work/website2024/s-302-dating/website/my-app/src/component/Billing.js
import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Import for navigation

const Billing = () => {
  const [billingData, setBillingData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Use navigate for redirection

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      const user = JSON.parse(storedUser);
      fetchBillingData(user.user, user.token);
    } else {
      setError('No user found');
      setLoading(false);
    }
  }, []);

  const fetchBillingData = async (userId, token) => {
    try {
      const response = await axios.get(`https://py-maracar.afaa.website/matching/api_env_2/user?user_id=${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const userPackages = response.data[0]?.user_packages || [];
      setBillingData(userPackages);  // Set only the user_packages to the state
    } catch (error) {
      console.error('Error fetching billing data:', error);
      setError('Failed to fetch billing data');
    } finally {
      setLoading(false);
    }
  };

  const handleViewPDF = (userId) => {
    navigate(`/view-pdf/${userId}`);  // Redirect to the PDF view page
  };

  const handlePayment = (packageId) => {
    console.log(`Proceeding to payment for package: ${packageId}`);

    const paymentForm = `
      <div class="payment-form mt-4">
        <h3>Payment for Package ID: ${packageId}</h3>
        <form class="form-group">
          <label for="cardNumber" class="form-label">Card Number</label>
          <input type="text" class="form-control mb-3" id="cardNumber" name="cardNumber" required />

          <label for="expiryDate" class="form-label">Expiry Date</label>
          <input type="text" class="form-control mb-3" id="expiryDate" name="expiryDate" required />

          <label for="cvv" class="form-label">CVV</label>
          <input type="text" class="form-control mb-3" id="cvv" name="cvv" required />

          <button type="submit" class="btn btn-success btn-block">Pay Now</button>
        </form>

        <button class="btn btn-secondary mt-3" onclick="location.reload()">Back to Billing</button> 
      </div>
    `;
    
    document.querySelector('.profile-card').innerHTML = paymentForm;
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="container profile-container mt-5">
      <div className="profile-card">
        <h2 style={{ fontWeight: 'bold', textAlign: 'center', marginBottom: '20px' }}>Billing History</h2>
        {billingData.length > 0 ? (
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead className="thead-dark">
                <tr>
                  <th>Date</th>
                  <th>Package</th>
                  <th>Amount</th>
                  <th>Status</th>
                  <th>Time</th>
                  <th>Actions</th>
                </tr>
              </thead>

              <tbody>
                {billingData.map((bill, index) => (
                  <tr key={index}>
                    <td>{new Date(bill.date).toLocaleDateString('en-GB')}</td>
                    <td>{bill.package}</td>
                    <td>{bill.price}</td>
                    <td>{bill.status_name}</td>
                    <td>{bill.time}</td>
                    <td className="d-flex justify-content-between">
                      <button 
                        className="btn btn-danger" 
                        onClick={() => handleViewPDF(bill.user_id)}>
                        View PDF
                      </button>

                      <button 
                        className="btn btn-success" 
                        onClick={() => handlePayment(bill.package_id)}>
                        Make Payment
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <p>No billing history available.</p>
        )}
      </div>
    </div>
  );
};

export default Billing;
