// /var/www/website2024/192.testproject.work/website2024/s-302-dating/website/my-app/src/api/apiConfig.js
const API_BASE_URL = 'https://py-maracar.afaa.website';
const UPLOAD_API_BASE_URL = 'https://go.afaa.website';


const getUserIDFromLocalStorage = () => {
  const storedUser = localStorage.getItem('user');
  return storedUser ? JSON.parse(storedUser).user : null;
};

export const API_URLS = {


  


  getUserID: () => {
    const storedUser = localStorage.getItem('user');
    return storedUser ? JSON.parse(storedUser).user : null;},
  
  
  LOGIN: `${API_BASE_URL}/login/api_env_2/check`,
  LOGOUT: `${API_BASE_URL}/logout/api/checkout`,


  
  FETCH_PROFILE: () => {
    const user_ID = getUserIDFromLocalStorage();
    return `${API_BASE_URL}/register/api_env_2/user?user_ID=${user_ID}`;
  },
  
  
  

  // FETCH_PROFILE: (user_ID) => {
  //   return user_ID ? `${API_BASE_URL}/register/api_env_2/user?user_ID=${user_ID}` : null;
  // },

  FETCH_MATCHING: (user_ID) => {
    return user_ID ? `${API_BASE_URL}/matching/api_env_2/user?user_id=${user_ID}` : null;
},

  
  EDIT_PROFILE: `${API_BASE_URL}/register/api_env_2/edit`,
  UPLOAD_PROFILE_IMAGE: `${UPLOAD_API_BASE_URL}/aachat/api/upload`,
  IMAGE_UPLOAD: `${API_BASE_URL}/img/api/upload`,

  // Registration and profile insert APIs (as part of the restructure)
  INSERT_BASIC_PROFILE: `${API_BASE_URL}/register/api_env_2/insert`,
  INSERT_SPECIFIC_PROFILE: `${API_BASE_URL}/register/api_env_2/specificinsert`,
  INSERT_PACKAGE_PROFILE: `${API_BASE_URL}/register/api_env_2/packagesinsert`,
  
  
};
