///var/www/website2024/192.testproject.work/website2024/s-302-dating/website/my-app/src/component/SlideMember.js

import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ProgressSpinner } from 'primereact/progressspinner'; // Import ProgressSpinner
import { Skeleton } from 'primereact/skeleton'; // Import Skeleton

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom'; // Add Link from react-router-dom
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// Import required modules from Swiper
import { Navigation, Pagination, Autoplay } from 'swiper/modules';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faCoins } from '@fortawesome/free-solid-svg-icons';
import '../style/SlideMember.scss';

const DoctorCard = ({ doctor }) => {
  const profileLink = `/profile-matching/${doctor.user_id ? doctor.user_id : 'invalid'}`; // Updated to use user_id

  console.log('Doctor user_id:', doctor.user_id); // Updated to use user_id

  return (
    <div className="card h-100 d-flex flex-column equal-height mb-2">
      <Link to={profileLink}>
        <img src={doctor.image} className="card-img-top centered-image" alt={doctor.name} />
      </Link>
      <div className="card-body flex-grow-1 d-flex flex-column justify-content-between">
        <h5 className="card-title">{doctor.name}</h5>
        <p className="card-text">{doctor.speciality}</p>
        <div className="d-flex justify-content-start align-items-center mb-2">
          <FontAwesomeIcon icon={faMapMarkerAlt} className="me-2" />
          <span>{doctor.location}</span>
        </div>
        <div className="d-flex justify-content-start align-items-center">
          <FontAwesomeIcon icon={faCoins} className="me-2" />
          <span className="package-name">{doctor.consultations}</span>
        </div>
      </div>
      <div className="card-footer d-flex justify-content-center">
        <Link to={profileLink}>
          <button className="btn btn-primary">View Profile</button>
        </Link>
      </div>
    </div>
  );
};




const SlideMember = ({ gender, ageFrom, ageTo }) => {

  const [doctors, setDoctors] = useState([]);
  const [occupationOptions, setOccupationOptions] = useState([]);
  const [nationalityOptions, setNationalityOptions] = useState([]);
  const [optionsLoaded, setOptionsLoaded] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // Retrieve token from local storage
  const currentUser = localStorage.getItem('user');
  const user = currentUser ? JSON.parse(currentUser) : null;
  const userToken = user ? user.token : '';


  useEffect(() => {

    
    if (!userToken) {
      setLoading(false);
      setIsAuthenticated(false);
      return;
    }

    setIsAuthenticated(true);

    const fetchOptions = async () => {
      try {
        const occupationResponse = await fetch('/data/occupationOptions.json');
        const occupationData = await occupationResponse.json();
        setOccupationOptions(occupationData);

        const nationalityResponse = await fetch('/data/nationalityOptions.json');
        const nationalityData = await nationalityResponse.json();
        setNationalityOptions(nationalityData);

        setOptionsLoaded(true); // Mark options as loaded
      } catch (error) {
        console.error('Error fetching occupation or nationality options:', error);
      }
    };

    fetchOptions();
  }, [userToken]); // Add 'userToken' to the dependency array



  useEffect(() => {
    if (optionsLoaded && isAuthenticated) {
      // Construct API URL dynamically based on the presence of gender, ageFrom, and ageTo
      let apiURL = 'https://py-maracar.afaa.website/matching/api_env_2/user';

      if (gender && ageFrom && ageTo) {
        apiURL += `?gender=${gender}&min_age=${ageFrom}&max_age=${ageTo}`;
      }

      // Console log the API URL for debugging
      console.log('Fetching data from API:', apiURL);

      fetch(apiURL, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${userToken}`,
        },
      })
        .then(response => response.json())

       
        .then(data => {
          // Log the data to inspect the structure
          console.log('API response data:', data);
      
          const transformedDoctors = data.map(user => {
            // Ensure that profile_data exists and is an array
            if (!user.profile_data || !Array.isArray(user.profile_data) || user.profile_data.length === 0) {
                console.error('Missing or invalid profile_data for user:', user);
                return null;
            }
        
            const profile = user.profile_data[0];  // Access first profile data element
            if (!profile.user_id) {
                console.error('Missing user_id in profile_data:', profile);
            }
        
            const occupationOption = occupationOptions.find(
                option => option.id === profile.occupation
            );
            const nationalityOption = nationalityOptions.find(
                option => option.id === profile.nationality
            );
            
            return {
                user_id: profile.user_id, // Use user_id explicitly
                name: profile.name || 'Unknown',
                speciality: occupationOption ? occupationOption.label : 'Loading...',
                location: nationalityOption ? nationalityOption.label : 'Loading...',
                consultations: user.user_packages && user.user_packages.length > 0 ? user.user_packages[0].package : 'No package available',
                image: profile.profile_image || 'https://afaa.website/s/3d0891.svg',
            };
        }).filter(doctor => doctor !== null);  // Filter out any null doctors
        
      
          setDoctors(transformedDoctors);
          setLoading(false);
      })

      
        .catch(error => {
          console.error('Error fetching data:', error);
          setLoading(false);
        });
    }
  }, [optionsLoaded, isAuthenticated, userToken, gender, ageFrom, ageTo, occupationOptions, nationalityOptions]);




  if (!isAuthenticated) {
    return (
      <Swiper
        spaceBetween={30}
        slidesPerView={4} // Set slidesPerView to 4 for Skeleton display
        autoplay={{ delay: 3000 }}
        pagination={{ clickable: true }}
        navigation
        loop={false}  // Loop is disabled since we are using static skeletons
        modules={[Navigation, Pagination, Autoplay]}
      >
        {[...Array(4)].map((_, index) => (
          <SwiperSlide key={index}>
            <Skeleton width="100%" height="300px" />
          </SwiperSlide>
        ))}
      </Swiper>
    );
  }

  if (loading) {
    return (
      <div className="spinner-container">
        <ProgressSpinner />
      </div>
    );
  }

  return (
    <Swiper
      spaceBetween={30}
      slidesPerView={doctors.length < 4 ? doctors.length : 4}  // Adjust slidesPerView based on number of slides
      autoplay={{ delay: 3000 }}
      pagination={{ clickable: true }}
      navigation
      loop={doctors.length >= 4}  // Enable loop only if there are enough slides
      modules={[Navigation, Pagination, Autoplay]}
    >
      {doctors.map((doctor, index) => (
        <SwiperSlide key={index}>
          <DoctorCard doctor={doctor} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default SlideMember;
