///var/www/website2024/192.testproject.work/website2024/s-302-dating/website/my-app/src/component/ViewPDF.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { API_URLS } from '../api/apiConfig';  // Import the API config
import '../style/ViewPDF.scss'; // Import the SCSS file for styling

const ViewPDF = () => {
  const { userId } = useParams();
  const [profileData, setProfileData] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [userPackages, setUserPackages] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        // Get the token from local storage
        const storedUser = localStorage.getItem('user');
        const token = storedUser ? JSON.parse(storedUser).token : null;

        // Make sure the token is available
        if (!token) {
          setError('Authorization token is missing.');
          setLoading(false);
          return;
        }

        const apiUrl = API_URLS.FETCH_MATCHING(userId); // Use the API URL from apiConfig

        // Add Authorization header with Bearer token
        const response = await axios.get(apiUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // Extract the required data from the API response
        const profile = response.data[0]?.profile_data[0];
        const details = response.data[0]?.user_details[0];
        const packages = response.data[0]?.user_packages[0];

        setProfileData(profile);  // Set profile data
        setUserDetails(details);  // Set user details
        setUserPackages(packages);  // Set user packages
        setLoading(false);
      } catch (error) {
        setError('Failed to fetch data');
        setLoading(false);
      }
    };
    fetchProfileData();
  }, [userId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="container mt-4 mb-4">
      {/* Receipt Box */}
      <div className="receipt">
        {/* Company Logo */}
        <div className="receipt-header">
          <img src="https://afaa.website/s/3d0891.svg" alt="Company Logo" />
        </div>
        
        <h2>Receipt for User ID: {userId}</h2>

        {profileData && userDetails && userPackages ? (
          <div className="p-4 border mt-4">
            {/* Two Column Layout */}
            <div className="row">
              {/* Left Column */}
              <div className="col-md-6">
                <p><strong>Name:</strong> {profileData.name} {profileData.sur_name}</p>
                <p><strong>Email:</strong> {profileData.email}</p>
                <p><strong>Telephone:</strong> {profileData.telephone}</p>
                <p><strong>Date of Birth:</strong> {new Date(profileData.birthday).toLocaleDateString('en-GB')}</p>
                <p><strong>Car Brand:</strong> {profileData.car_brand}</p>
              </div>

              {/* Right Column */}
              <div className="col-md-6">
                <p><strong>Income Class:</strong> {userDetails.income_classes_label}</p>
                <p><strong>Occupation:</strong> {userDetails.occupation_label}</p>
                <p><strong>Matching Gender:</strong> {userDetails.gender_match_label}</p>
              </div>
            </div>

            {/* Subscription Package Section */}
            <h3 className="mt-4">Subscription Package</h3>
            <div className="row">
              <div className="col-md-6">
                <p><strong>Package:</strong> {userPackages.package}</p>
                <p><strong>Price:</strong> {userPackages.price}</p>
              </div>
              <div className="col-md-6">
                <p><strong>Status:</strong> {userPackages.status_name}</p>
                <p><strong>Date:</strong> {new Date(userPackages.date).toLocaleDateString('en-GB')}</p>
                <p><strong>Time:</strong> {userPackages.time}</p>
              </div>
            </div>
          </div>
        ) : (
          <p>No profile data available.</p>
        )}
      </div>
    </div>
  );
};

export default ViewPDF;
