///var/www/website2024/192.testproject.work/website2024/s-302-dating/website/my-app/src/page/home.js
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'swiper/swiper-bundle.css';
import PackagePrice from '../component/PackagePrice';
import SlideMember from '../component/SlideMember';
import BannerForm from '../component/BannerForm';
import '../style/Home.scss';

const Home = () => {
  const currentUser = localStorage.getItem('user');
  const user = currentUser ? JSON.parse(currentUser) : null;

  return (
    <>
      <section className="banner-section">
        <div className="container">
          <div className="section-wrapper">
            <div className="row jc-fe">

              <div className="intro-form-inner">
                <h3>Find Your Perfect Match Today</h3>
                <p>
                Join our vibrant community of singles looking for meaningful connections. Whether you're searching for friendship, romance, or your soulmate, our platform offers a safe and enjoyable experience to meet new people. Start your journey to love with us today and discover a world of possibilities.</p>
              </div>

              <div className="col-lg-12">
                <div className="banner-content flex-form-index">
                  <div className="intro-form">
                    <BannerForm />
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>

      <PackagePrice />

      <div className="container mt-4 mb-5">
        <div className="text-center mb-4">
          {user ? (
            <h2 className="danger-text">FIND MATCH</h2>
          
          ) : (
            <h2 className="danger-text">APPLY FOR OUR MEMBERSHIP</h2>
          )}
        </div>
        <SlideMember />
      </div>
    </>
  );
}

export default Home;
