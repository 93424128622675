///var/www/website2024/192.testproject.work/website2024/s-302-dating/website/my-app/src/component/ProfileDetail.js

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';  // Added useNavigate for redirection
import { ProgressSpinner } from 'primereact/progressspinner';
import styles from '../style/ProfileDetail.module.scss';  // Import the SCSS module
import { API_URLS } from '../api/apiConfig';
import { format } from 'date-fns';

// Create a function to format numbers with commas
const formatCurrency = (value) => {
    return value ? parseFloat(value).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2 }) : '$0.00';
};

const ProfileDetail = () => {
    const { user_ID } = useParams();
    const navigate = useNavigate();  // Used to redirect the user if unauthorized
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Get the token object from localStorage
    const storedUser = localStorage.getItem('user');
    const parsedUser = storedUser ? JSON.parse(storedUser) : null;
    const token = parsedUser ? parsedUser.token : null;

    // Add the following utility function at the top of the component file (before return statement or inside the component).
    const formatYesNo = (value) => value === 1 ? 'Yes' : 'No';


    useEffect(() => {
        if (!token) {
            setError('No token found, redirecting to login...');
            navigate('/login');  // Redirect to login if no token is found
            return;
        }

        if (!user_ID) {
            setError('User ID is missing');
            setLoading(false);
            return;
        }

        const fetchUserData = async () => {
            try {
                console.log('Sending request with token:', token);  // Log token for debugging
                const apiUrl = API_URLS.FETCH_MATCHING(user_ID); // Use the API URL from apiConfig
                const response = await fetch(apiUrl, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,  // Send the token
                    },
                });

                if (!response.ok) {
                    if (response.status === 401) {
                        setError('Unauthorized. Redirecting to login...');
                        localStorage.removeItem('user');  // Clear user data if invalid
                        navigate('/login');  // Redirect the user to login
                        return;
                    }
                    throw new Error('Failed to fetch user data');
                }

                const data = await response.json();
                console.log('API response:', data);  // Log API response for debugging
                setUserData(data[0]);
                setLoading(false);
            } catch (error) {
                setError(error.message);
                setLoading(false);
            }
        };

        fetchUserData();
    }, [user_ID, token, navigate]);  // Include navigate in the dependency array

    if (loading) {
        return <ProgressSpinner />;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div className={styles['profile-detail-container']}>
            <div className={`card mb-5 ${styles['profile-card']}`}>
                <div className="card-body">

                    <div className={styles['profile-additional-info']}>
                        

                        <img
                            src={userData.profile_data[0].profile_image || 'https://afaa.website/s/3d0891.svg'}
                            alt="Profile"
                            className={styles['profile-image']}
                        />



                        <div className={styles['info-section']}>
                            <p ><strong>Name:</strong> {`${userData.profile_data[0].name} ${userData.profile_data[0].sur_name}`}</p>
                            <p ><strong>Age:</strong> {`${userData.profile_data[0].age}`}</p>

                            <p><strong>Telephone:</strong> {userData.profile_data[0].telephone}</p>
                            <p><strong>Birthday:</strong> {new Date(userData.profile_data[0].birthday).toLocaleDateString()}</p>
                            <p><strong>Gender: </strong> {userData.user_details[0].gender_label}/ Matching: {userData.user_details[0].gender_match_label} </p>
                        </div>



                        <div className={styles['info-section']}>
                            <p><strong>Email:</strong> {userData.profile_data[0].email}</p>
                        </div>

                        <div className={styles['info-section']}>
                            <p><strong>Income:</strong> {formatCurrency(userData.profile_data[0].income)}</p>
                            <p><strong>Net Worth:</strong> {formatCurrency(userData.profile_data[0].net_worth)}</p>
                        </div>

                        <div className={styles['info-section']}>
                            <p><strong>Private Airplane:</strong> {formatYesNo(userData.profile_data[0].private_airplane)}</p>
                            <p><strong>Private Yacht:</strong> {formatYesNo(userData.profile_data[0].private_yacht)}</p>
                        </div>


                        <div className={`${styles['user-details']} ${styles['align-right']}`}>
                            <p><strong>Income Class:</strong> {userData.user_details[0].income_classes_label}</p>
                            <p><strong>Looking For:</strong> {userData.user_details[0].looking_for_label}</p>
                        </div>

                    </div>




                    {userData.user_details && userData.user_details.length > 0 && (
                            <div className={`${styles['profile-additional-info']} mt-4`}>
                            <div className={`${styles['user-details']}`}>

                                <p>Car : {userData.user_details[0].car_label} / Conut : {userData.profile_data[0].number_of_cars} / Private Car : {formatYesNo(userData.profile_data[0].private_car)}</p>

                                <p>Occupation: {userData.user_details[0].occupation_label} / Matching: {userData.user_details[0].occupation_match_label}</p>

                                <p>Nationality: {userData.user_details[0].nationality_label} / Matching : {userData.user_details[0].nationality_match_label}</p>
                            </div>
                        </div>
                    )}



                    {userData.user_packages.length > 0 && (
                        <div className={styles['package-details']}>
                            <h6>Package Details</h6>
                            <p>Package: {userData.user_packages[0].package}</p>
                            <p>Price: ${userData.user_packages[0].price}</p>
                            <p>Date: {format(new Date(userData.user_packages[0].date), 'dd MMM yyyy')}</p>

                        </div>
                    )}





                </div>
            </div>
        </div>
    );
};

export default ProfileDetail;
