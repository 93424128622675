import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Card } from 'primereact/card';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import '../style/Package.scss';

const Package = () => {
  const classes = [
    { name: 'Millionaire Class', income: '1 - 9 million USD' },
    { name: 'Decamillionaire Class', income: '10 - 49 million USD' },
    { name: 'Decamillionaire Class (Gold)', income: '50 - 99 million USD' },
    { name: 'Centimillionaire Class', income: '100 - 499 million USD' },
    { name: 'Centimillionaire Class (Gold)', income: '500 - 999 million USD' },
    { name: 'Billionaire Class', income: '1,000 - 4,999 million USD' },
    { name: 'Billionaire Class (Gold)', income: '5,000 - 9,999 million USD' },
    { name: 'Decabillionaire Class', income: '10,000 - 49,999 million USD' },
    { name: 'Decabillionaire Class (Gold)', income: '50,000 - 99,999 million USD' },
    { name: 'Centibillionaire Class', income: 'From 100,000 million USD' },
  ];

  const navigate = useNavigate();

  const handleCardClick = (selectedClass) => {
    navigate('/register', { state: { selectedClass, step: 2 } });
  };

  // Split the classes array into two equal parts for the two columns
  const firstHalf = classes.slice(0, 5);
  const secondHalf = classes.slice(5);

  return (
    <Container className="mt-4">

      <div className='mt-4 mb-4' >
        <h2 className="danger-text">MEMBERSHIP LEVELS</h2>
      </div>


      <Row>
        <Col>
          {firstHalf.map((item, index) => (
            <Card
              key={index}
              title={item.name}
              subTitle={item.income}
              className="mb-4 text-center"
              onClick={() => handleCardClick(item)}
              style={{ cursor: 'pointer' }}
            >
              <p className="m-0">Click to select {item.name}</p>
            </Card>
          ))}
        </Col>
        <Col>
          {secondHalf.map((item, index) => (
            <Card
              key={index}
              title={item.name}
              subTitle={item.income}
              className="mb-4 text-center"
              onClick={() => handleCardClick(item)}
              style={{ cursor: 'pointer' }}
            >
              <p className="m-0">Click to select {item.name}</p>
            </Card>
          ))}
        </Col>
      </Row>
    </Container>
  );
};

export default Package;
