///var/www/website2024/192.testproject.work/website2024/s-302-dating/website/my-app/src/page/register.js

import React, { useState, useRef, } from 'react';
import { Toast } from 'primereact/toast';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useLocation } from 'react-router-dom';

import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import Step1IncomeClass from '../component/Step1IncomeClass';
import Step2PersonalInfo from '../component/Step2PersonalInfo';
import Step3PackageSelection from '../component/Step3PackageSelection';

import 'primereact/resources/themes/saga-blue/theme.css'; // Theme
import 'primereact/resources/primereact.min.css'; // Core CSS
import 'primeicons/primeicons.css'; // Icons

import { insertBasicProfile, insertSpecificProfile, insertPackageProfile } from '../api/api';


const Register = () => {
  const location = useLocation();
  const initialStep = location.state?.step || 1;
  const initialSelectedClass = location.state?.selectedClass || null;

  const [step, setStep] = useState(initialStep);
  const [loading, setLoading] = useState(false);
  const [selectedClass, setSelectedClass] = useState(initialSelectedClass);

  // Add birthday to initial formData state
  const [formData, setFormData] = useState({
    first_name: '',
    surname: '',
    social_media: '',
    gender: '',
    matching_gender: '',
    nationality: '',
    matching_nationality: '',
    age: '',
    matching_age: '',
    occupation: '',
    matching_occupation: '',
    private_airplane: false,
    private_yacht: false,
    private_car: false,
    car_brand: '',
    number_of_cars: 0,
    income: 0,
    net_worth: 0,
    looking_for: '',
    telephone: '',
    email: '',
    password: '',
    birthday: '', // Add this field
  });

  const toast = useRef(null);

  const handleNext = (data) => {
    if (step === 1) {
      setSelectedClass(data);
    } else if (step === 2) {
      setFormData(data);
    }
    setStep(step + 1);
  };

  const handleBack = () => {
    setStep(step - 1);
  };


  // Ensure the handleChange function properly updates the birthday field
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleComplete = async (selectedPackage) => {
    setLoading(true);

    // Separate basic profile
    const basicProfile = {
      first_name: formData.first_name,
      sur_name: formData.surname,
      social_media: formData.social_media,
      email: formData.email,
      telephone: formData.telephone,
      password: formData.password,
      ip: "192.168.1.15",
      birthday: formData.birthday, // Include birthday field
      image: ""
    };

    console.log("Submitting the following basic profile to the register API:", JSON.stringify(basicProfile));

    try {
      // Insert basic profile via API
      const userData = await insertBasicProfile(basicProfile);
      const user_id = userData.user_id;

      const specificProfile = {
        user_id,
        private_airplane: formData.private_airplane,
        private_yacht: formData.private_yacht,
        private_car: formData.private_car,
        car_brand: formData.car_brand, // Ensure this is a string
        number_of_cars: formData.number_of_cars,
        income: formData.income,
        net_worth: formData.net_worth,
        looking_for: formData.looking_for, // Ensure this is a string
        occupation: formData.occupation, // Added occupation
        matching_occupation: formData.matching_occupation, // Added matching occupation
        gender: formData.gender, // Added gender
        matching_gender: formData.matching_gender, // Added matching gender
        nationality: formData.nationality, // Added nationality
        matching_nationality: formData.matching_nationality,
        income_class: {
          id: selectedClass.id,
          name: selectedClass.name,
          range: selectedClass.range
        }
      };
     
      // Insert specific profile via API
      await insertSpecificProfile(specificProfile);

      // Separate package profile
      const packageProfile = {
        user_id,
        package_name: selectedPackage.id,
        price: selectedPackage.price,
        features: selectedPackage.features,
        isHighlighted: selectedPackage.isHighlighted,
        date: new Date().toISOString().slice(0, 10),
        time: new Date().toLocaleTimeString('it-IT')
      };

      // Insert package profile via API
      await insertPackageProfile(packageProfile);

      toast.current.show({ severity: 'success', summary: 'Registration Successful', detail: 'You are now registered.' });

      // Reset all steps
      setStep(1);
      setSelectedClass(null);
      setFormData({
        first_name: '',
        surname: '',
        social_media: '',
        gender: '',
        matching_gender: '',
        nationality: '',
        matching_nationality: '',
        age: '',
        matching_age: '',
        occupation: '',
        matching_occupation: '',
        private_airplane: false,
        private_yacht: false,
        private_car: false,
        car_brand: '',
        number_of_cars: 0,
        income: 0,
        net_worth: 0,
        looking_for: '',
        telephone: '', // add this field to your form data
        email: '', // add this field to your form data
        password: '', // add this field to your form data
        birthday: '', // add this field to your form data
      });

    } catch (error) {
      console.error('Error registering user:', error);
      toast.current.show({ severity: 'error', summary: 'Registration Failed', detail: 'Error: ' + error.message });
    } finally {
      setLoading(false);
    }
  };


  return (
    <Container className="register-container mt-5 mb-20">
      <Toast ref={toast} />

      <Row>
        <Col xs={12} md={12} xl={12}>
          <h1> START YOUR JOURNEY HERE </h1>

          {loading ? (
            <ProgressSpinner />
          ) : (
            <>
              {step === 1 && <Step1IncomeClass onNext={handleNext} />}
              {step === 2 && (
                <Step2PersonalInfo
                  onNext={handleNext}
                  onBack={handleBack}
                  formData={formData}
                  handleChange={handleChange}
                />
              )}
              {step === 3 && (
                <Step3PackageSelection
                  incomeClassId={selectedClass?.id}
                  onComplete={handleComplete}
                  onBack={handleBack}
                />
              )}
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default Register;
