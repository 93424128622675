// /var/www/website2024/192.testproject.work/website2024/s-302-dating/website/my-app/src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import AppNavbar from './component/Navbar';
import Home from './page/home';
import Register from './page/register';
import Login from './page/login';
import ForgotPassword from './page/forgot-password';
import Footer from './component/Footer';
import Profile from './page/Profile'; // Import the Profile page
import Matching from './component/Matching'; // Import the Matching component
import FindMatch from './page/FindMatch'; // Import the FindMatch page
import ProfileDetail from './component/ProfileDetail'; // Import the ProfileDetail component
import ViewPDF from './component/ViewPDF';  // Import the ViewPDF component


function App() {
  return (
    <Router basename="/">
      <div className="App">
        <AppNavbar />
        <Routes>
          {/* Home route */}
          <Route exact path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />

          {/* Registration and authentication routes */}
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />

          {/* Profile route (unchanged) */}
          <Route path="/profile" element={<Profile />} /> {/* Keep this route unchanged */}

          {/* Matching and other pages */}
          <Route path="/matching" element={<Matching />} />
          <Route path="/find-match" element={<FindMatch />} />

          {/* Updated ProfileDetail route with new URL */}
          <Route path="/profile-matching/:user_ID" element={<ProfileDetail />} />

          {/* Add route for ViewPDF */}
          <Route path="/view-pdf/:userId" element={<ViewPDF />} /> {/* New route for the PDF view */}
        </Routes>

        {/* Footer Component */}
        <Footer />
      </div>
    </Router>
  );
}

export default App;
