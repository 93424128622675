///var/www/website2024/192.testproject.work/website2024/s-302-dating/website/my-app/src/api/api.js

import axios from 'axios';
import { API_URLS } from './apiConfig';


export const insertBasicProfile = async (basicProfile) => {
    try {
        const response = await axios.post(API_URLS.INSERT_BASIC_PROFILE, basicProfile, {
            headers: { 'Content-Type': 'application/json' }
        });
        return response.data;
    } catch (error) {
        throw new Error('Failed to insert basic profile');
    }
};

export const insertSpecificProfile = async (specificProfile) => {
    try {
        const response = await axios.post(API_URLS.INSERT_SPECIFIC_PROFILE, specificProfile, {
            headers: { 'Content-Type': 'application/json' }
        });
        return response.data;
    } catch (error) {
        throw new Error('Failed to insert specific profile');
    }
};

export const insertPackageProfile = async (packageProfile) => {
    try {
        const response = await axios.post(API_URLS.INSERT_PACKAGE_PROFILE, packageProfile, {
            headers: { 'Content-Type': 'application/json' }
        });
        return response.data;
    } catch (error) {
        throw new Error('Failed to insert package profile');
    }
};


export const fetchProfile = async (userId, token) => {
    const url = API_URLS.FETCH_PROFILE(userId);  // Ensure the URL is generated correctly
    if (!url) throw new Error('User ID is missing or invalid.');

    try {
        const response = await axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching profile:', error);
        throw new Error('Failed to fetch profile data');
    }
};



export const updateProfile = async (userId, token, updatedProfile) => {
    const url = API_URLS.EDIT_PROFILE;  // The URL is static in this case
    try {
        const response = await axios.post(url, updatedProfile, {
            params: { user_ID: userId },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error updating profile:', error);
        throw new Error('Failed to update profile');
    }
};

